@use '@angular/material' as mat;

@include mat.all-component-typographies();
@include mat.core();

@import '@angular/material';
@import 'src/app/styles/colors';
@import 'src/app/styles/repeat';
@import '@angular/cdk/overlay-prebuilt.css';

:root {
  --backGround: #{$background};
}

.mat-mdc-checkbox-touch-target {
  height: 18px !important;
}

:root {
  --customFontSize: 7px;
  --mat-option-label-text-size: 1.4rem;

  .mat-mdc-standard-chip .mdc-evolution-chip__text-label {
    --mdc-chip-label-text-size: 1.4rem;
    --mdc-chip-label-text-weight: 500;

  }

  --font-size: '8px';
  --font-family: 'Roboto';
  --mdc-plain-tooltip-container-color: $workspace;
  --mdc-plain-tooltip-supporting-text-color: $dark-text;
  --mdc-filled-button-container-height: 3.6rem;
  --mdc-plain-tooltip-supporting-text-size: 1.4rem;
  --navigationItemColor: #{$light-text};
  --navigationHeaderWeight: 400;
  --nav-primary: 'green';
  --mdc-checkbox-state-layer-size: 16px;
  --mdc-list-list-item-label-text-size: 16px;
  --mdc-outlined-card-outline-width: 0;
  --mdc-dialog-supporting-text-color: #000000de;
  --mat-menu-item-label-text-size: 1.4rem;
  --mdc-dialog-supporting-text-size: 14px;
  --mdc-list-list-item-label-text-font: 'Roboto';
}

#childItem .mdc-list-item__content {
  //height: inherit;
}

.mat-mdc-tab-labels > div {
  justify-content: flex-start;
}

.mat-slide-toggle {
  color: #678BAC !important;
}

mat-card {
  padding: 16px;
}

mat-checkbox label {
  white-space: nowrap;
}

.form-control {
  border-radius: 5rem;
  padding-top: 10px;
  padding-bottom: 10px;

}

button > span {
  white-space: nowrap !important;
}

.mat-mdc-form-field-focus-overlay {
  display: none;
}

.mdc-line-ripple--active::after {
  opacity: 0 !important;
}

.mdc-notched-outline__leading, .mdc-notched-outline__trailing {
  border-width: 0 !important;
}

app-custom-table mat-paginator .mat-mdc-text-field-wrapper {
  padding: 0;
}

.mat-mdc-form-field-infix {
  padding-top: 9px !important;
  padding-bottom: 7px !important;
}

div.mat-mdc-text-field-wrapper {
  padding-top: 0;
  padding-left: 0;
  //width: 105%;
  background-color: transparent !important;
}

telco-edge-config-step16{
  div.mat-mdc-text-field-wrapper {
    padding: 0;
  }
}

div.mdc-text-field--filled .mdc-line-ripple::before {
  border-bottom-width: 0;
}

.mat-mdc-select-arrow-wrapper {
  height: 16px !important;
}

mat-card button.mat-mdc-button-base {
  width: fit-content;
  //  height: 28px;
}

button.mat-mdc-unelevated-button {
  > .mat-icon {
    font-size: 2.4rem;
    width: 24px;
    height: 24px;
    margin-left: 0;
    margin-right: 2px;
  }
}

.mat-mdc-paginator-page-size-select {
  width: 56px !important;
}

.mdc-button__label {
  white-space: nowrap;
}

mat-form-field {
  width: 30rem;
}

$my-button-level: mat.define-typography-level(
  $font-size: 1.4rem,
  $font-weight: 500,
);
$my-primary: mat.define-palette(mat.$indigo-palette, 500);
$my-accent: mat.define-palette($secondaryPallet, 500);

$my-custom-level: mat.define-typography-level(
  $font-family: var(--font-family),
  $font-weight: 400,
  $font-size: var(--customFontSize),
  $line-height: 1,
  $letter-spacing: normal,
);
$my-typography: mat.define-typography-config(
  $font-family: var(--font-family),
  $body-1: $my-custom-level,
  $button: $my-button-level,
);
$dialog-padding: 16px;

$my-theme: mat.define-light-theme((
  color: (
    primary: $my-primary,
    accent: $my-accent,
    background:(
      tooltip:'white'
    )
  ),
  dialog: (
    padding: $dialog-padding,
  ),
  typography: $my-typography,
));

@include mat.core-theme($my-theme);
@include mat.all-component-themes($my-theme);

$nav-list--level: mat.define-typography-level(
  $font-size: 15px
);

$custom-navigation-typography: mat.define-typography-config(
  $body-1: $nav-list--level,
);
.mdc-list {
  padding-bottom: 0 !important;
}

@include mat.list-typography($custom-navigation-typography);

//$button-level:  mat.define-typography-config(
//  $font-family: 'Comic Sans',
//);
//
//$custom-button-typography: mat.define-typography-config(
//  $body-1: $button-list--level,
//);
//
//@include mat.button-typography($button-level);

:root {
  --primary: #{$primary};
}

*,
*::after,
*::before {
  margin: 0;
  padding: 0;
  box-sizing: inherit;
}

html {
  font-size: 62.5%;
  @media(max-width: 1500px) {
    font-size: 56.25%;
  }
  @media(max-width: 1294px) {
    font-size: 50%;
  }
}

html, body {
  margin: 0;
  height: 100%;
  overflow: hidden;
}

body {
  box-sizing: border-box;
  font-size: 10px;
}

//.mat-mdc-select-panel {
//  margin-top: 4.5rem;
//}

.mat-mdc-dialog-container {
  background-color: var(--backGround);
}

.custom-mobile-modalbox > mat-dialog-container {
  background-color: white;
  min-width: 300px;
  padding: 0 10px 0 10px;
}

app-sidebar .mat-drawer-container {
  background-color: var(--backGround);
  color: $dark-text;
}

app-sidebar {
  mat-sidenav > div > mat-nav-list > div.sidenav-footer-container {
    font-family: var(--font-family);
    font-weight: var(--navigationHeaderWeight);
    color: var(--navigationItemColor);
  }
}

app-navbar .mat-toolbar {
  background: var(--primary);
  color: $dark-text;
}

body > app-root > main > telco-entry .mat-nav-list .mat-mdc-list-item {
  color: var(--navigationItemColor);
}

body > app-root > main > telco-entry mat-nav-list mat-list-item > span > div > span {
  font-weight: var(--navigationHeaderWeight);
}

.mat-icon {
  background-repeat: no-repeat;
  display: inline-block;
  fill: currentColor;
  height: 2.5rem;
  color: $light-text;
  width: 2.5rem;
}

.mat-mdc-card {
  // border-radius: .5rem !important;
  box-shadow: none !important;
  padding: 2rem;
}

.mat-mdc-cell {
  color: $dark-text;
  font-size: 1.4rem;
}

::-webkit-scrollbar {
  width: 1rem;

}

/* Track */
::-webkit-scrollbar-track {
  background: #f1f1f1;
}

/* Handle */
::-webkit-scrollbar-thumb {
  background: rgb(180, 180, 180);
  border-radius: .5rem;
}

/* Handle on hover */
::-webkit-scrollbar-thumb:hover {
  background: #6b6b6b;
}

.myPanelClass {
  margin-top: 3rem !important;
}

.alertDropDown.mat-mdc-menu-panel {
  max-width: 100rem !important;
  background-color: $background;
  padding: 0 8px 0 8px;
}

.ucpeMenuPanel {
  button.mat-mdc-menu-item {
    height: 3rem;
    min-height: 3rem;
    line-height: 3rem;
    width: 16rem;
    font-size: 1.4rem;
  }
}

.serviceTempCtlTabBody {
  width: 46rem;
  height: 60rem;
  overflow: auto;
  resize: both;
}

.serviceTempCtlTabLabel {
  height: 2rem !important;
}

@media (min-width: 576px) {
  .modal-dialog {
    min-width: 57.6rem !important;
    max-width: min-content !important;
  }
}

.mdc-checkbox__ripple {
  display: none;
}

.advancedFilterSelect {
  border-radius: 0 !important;
  border: solid 1px #6b6b6b;
  width: 18rem !important;
}

.advancedFilterSelect .mat-mdc-option {
  min-height: 19.9px !important;
  span {
    white-space: nowrap !important;
  }
}

.advancedFilterSelect .mat-mdc-option:hover {
  background-color: #678BAC !important;
  color: white;
}

/* TODO(mdc-migration): The following rule targets internal classes of option that may no longer apply for the MDC version.*/
.advancedFilterSelect .mat-mdc-option.mat-selected:not(.mat-option-disabled) {
  color: rgba(0, 0, 0, 0.87);
}

.advancedFilterSelect .mat-pseudo-checkbox-checked {
  background-color: #678BAC;
}

.form-control:disabled, .form-control[readonly] {
  background-color: #e9ecef;
  opacity: 1;
}

.tooltipLook {
  background-color: $workspace;
  border: 1px solid $dark-text;
  border-radius: 4px;
  white-space: pre-line;
  max-width: unset !important;
}

//button mat-icon:first-child {
//  margin-right: 15px;
//}

/* TODO(mdc-migration): The following rule targets internal classes of card that may no longer apply for the MDC version.*/
.mat-card-header-text {
  margin: 0 !important;
}

//.cdk-overlay-pane {
//  margin: 10px 0;
//}

.mat-sort-header-content {
  text-transform: capitalize !important;
}

.mat-mdc-form-field-infix {
  width: 18rem !important;
  font-size: 14px;
}

input.mat-mdc-chip-input {
  width: 15rem !important;
  flex: 0 !important;
}

.mat-drawer-inner-container {
  overflow-x: hidden !important;
}

.mat-mdc-dialog-content {
  overflow-x: hidden !important;
}

.mat-sort-header-content {
  font-size: 1.4rem;
}

/* TODO(mdc-migration): The following rule targets internal classes of checkbox that may no longer apply for the MDC version.*/
.mdc-checkbox__background, .mdc-checkbox {
  height: 1.6rem !important;
  width: 1.6rem !important;
}

.mat-mdc-menu-panel {
  min-height: fit-content !important;
}

mat-label {
  font-size: 1.4rem;
}

/* TODO(mdc-migration): The following rule targets internal classes of checkbox that may no longer apply for the MDC version.*/
.mat-checkbox-layout .mat-checkbox-label {
  font-size: 1.4rem;
}

h4 {
  font-size: 1.4rem;
}

li {
  font-size: 1.4rem;
}

li.xng-breadcrumb-item {
  // font-size: 1.8rem;
  font-size: 1.4rem;
}


/* TODO(mdc-migration): The following rule targets internal classes of tabs that may no longer apply for the MDC version.*/
.mat-tab-label .mat-tab-label-content {
  font-size: 1.4rem;
}

/* TODO(mdc-migration): The following rule targets internal classes of chips that may no longer apply for the MDC version.*/
.mat-chip-list-wrapper input.mat-mdc-input-element, .mat-chip-list-wrapper .mat-mdc-standard-chip {
  font-size: 1.4rem;
}

.datepicker-dialog-panel .mat-mdc-dialog-container {
  display: none !important;
}

.datepicker-dialog-backdrop {
  opacity: 0;
}

/* TODO(mdc-migration): The following rule targets internal classes of tabs that may no longer apply for the MDC version.*/
.mat-tab-body-content {
  overflow-x: hidden !important;
}

/* TODO(mdc-migration): The following rule targets internal classes of tabs that may no longer apply for the MDC version.*/
.mat-tab-body-content {
  display: flex;
  flex-direction: column;
}

/* TODO(mdc-migration): The following rule targets internal classes of tabs that may no longer apply for the MDC version.*/
.mat-tab-label-container {
  flex-grow: 0 !important;
}

/* TODO(mdc-migration): The following rule targets internal classes of tabs that may no longer apply for the MDC version.*/
.mat-tab-body-wrapper {
  flex: 1 0 1px;
  overflow: auto !important;
}

.mat-mdc-tab-group {
  height: 100%;
}

/* TODO(mdc-migration): The following rule targets internal classes of tabs that may no longer apply for the MDC version.*/
.mat-mdc-tab-body.mat-tab-body-active {
  flex-grow: 1;
}

.mat-autocomplete-panel {
  margin-top: -16px;
}

//@include mat.legacy-list-theme($my-theme);

.mdc-dialog__container {
  .mdc-dialog__surface {
    background-color: #DFEBF6 !important;
    box-shadow: none !important;
    overflow: hidden;
  }
}

mat-dialog-content {
  margin-left: -24px !important;
}

.mat-mdc-dialog-container {
  display: block;
  padding: 8px;
  border-radius: 4px;
  box-sizing: border-box;
  overflow: auto;
  outline: 0;
  width: 100%;
  height: 100%;
  min-height: inherit;
  max-height: inherit;
}

.mat-step-header .mat-step-icon-selected{
  background-color: #678BAC;
}

.mat-step-header .mat-step-icon-state-edit{
  background-color: #678BAC;
}

div.editor-topology-container > app-ng-vis-view > div.topology-container{ // Not good practice. Will fix later
  height: 100%;
  width: 100%;

  & > div.visDiv-container{
    height: 100%;
    width: 100%;

    & > div.visDiv{
      height: 100%;
      width: 100%;

      & > div#visDrawTopology{
        height: 100%;
        width: 100%;
        max-width: none;
      }
    }
  }
}

.mat-step-label.mat-step-label-selected{ // Will fix later
  font-size: 1.6rem !important; //Because of commit "chars" from Nikolay Torbev, --customFontSize is 7px; --mat-stepper-header-selected-state-label-text-size: var(--customFontSize);
}

telco-home-tabs{
  .mat-mdc-tab.mdc-tab { //whole tab
    border-radius: 5px 5px 0px 0px;
    height: 40px;
    max-width: 285px;

    &.mdc-tab-indicator--active {
      background-color: $workspace;
    }
  }

  .mat-mdc-tab-labels{ //gap between tabs
    gap: 1rem;
  }

  .mdc-tab:not(:last-child):after { //divider between tabs
    content: '';
    position: absolute;
    top: 8px;
    right: -.5rem;
    bottom: 0;
    width: 1px;
    height: 24px;
    background: white;
  }

  .mdc-tab-indicator--active .mdc-tab-indicator__content{ //no angular border-bottom
    opacity: 0;
  }

  .mat-mdc-tab.mdc-tab .mdc-tab__text-label {
    color: white;
    width: 100%;
    justify-content: space-between;
    display: flex;
    flex-direction: row;
    gap: 1rem;
  }

  .mat-mdc-tab-group.mat-mdc-tab-group-stretch-tabs>.mat-mdc-tab-header .mat-mdc-tab{
    flex-grow: 0;
  }

  .mat-mdc-tab:not(.mat-mdc-tab-disabled).mdc-tab--active .mdc-tab__text-label {
    color: #343434;
    align-items: center;
  }

  .mat-mdc-tab-header-pagination-controls-enabled .mat-mdc-tab-header-pagination .mat-mdc-tab-header-pagination-chevron {
    border-color: white;
  }
}

.xng-breadcrumb-trail{
  font-size: 1.6rem;
  color: #343434;
  font-weight: 500;
}

app-advanced-filter{
  .mdc-checkbox{
    margin-left: 1px;
  }
}

button{
  font-family: var(--font-family);
}

app-service-design-step2{
  .mat-mdc-tab-body-wrapper{
    flex: 1;
  }
}

app-pod,
app-virt-machine{
  .mat-mdc-tab-body-wrapper{
    padding: 2rem;
  }
}
