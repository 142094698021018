@import 'src/app/styles/colors';

:root {
  --background: #{$background};
}

:root {
  //font-size: var(--mdc-typography-body1-font-size, 16px);
  //font-size: var(--mdc-list-list-item-label-text-size, 16px);

}

.form-control {
  padding: 1rem;
  border: 1px solid #aaa !important;
  font-size: 1.4rem !important;
  border-radius: .5rem;
}

mat-mdc-form-field {
  width: 30rem;
}

.inline-style-fix {
  width: 100%;  
  border: 15px solid #DFEBF6; 
  border-radius: 5px;
  padding: 10px;
}

//.mat-element {
//  width: 27.5rem;
//}

.red-required {
  color: red;
}

.unlucky-margin {
  //margin-top: -1.3rem;
}

.elements {
  margin-bottom: 2rem;
}

input[type="checkbox"] {
  margin-left: 5px;
}

:root {
  --background: #{$background};
}

.container_all {
  background-color: var(--background);
  margin-top: 2%;
  padding: 2rem;
  border-radius: .5rem;
}

.container_content {
  background-color: $workspace;
  border-radius: .5rem;
  padding: 2rem;
}

.go_back {
  color: $light-text;
  background-color: $secondary;
  height: 3.6rem
}

#save-entries {
  color: $light-text;
  background-color: $green;
}

.radio_margin {
  margin-left: .5rem;
  margin-right: .3rem;
}

.table_responsive {
  height: calc(100% - 56px);
  display: flex;
  flex-direction: column;

  .mat-mdc-row, tr.mat-mdc-header-row {
    height: 3rem !important;
  }

  .mat-mdc-row:nth-child(2n+1) {
    background-color: #f0f1f6;
  }

  th {
    white-space: nowrap;
  }

  td {
    white-space: nowrap;
    padding-right: 3rem;
    width: max-content;
  }
}

.labelHeader {
  font-size: 2.1rem;
  font-weight: 500;
}

#acknowledgeButton {
  color: $light-text;
  background-color: $green;
}

#add-entries, #add-new-app, #attachProfile {
  color: $light-text;
  background-color: $primary;
}

#edit-entries, #edit-entries-users {
  color: $light-text;
  background-color: $green;
}

#delete-entries, #delete-entries-users, #delete-entries-mng, #delete-entries-app, #delete-licences, #service-imgCtlg-delete-entries, #delete-profile {
  color: $light-text;
  background-color: $red;
}

#setting-entries {
  color: $light-text;
  background-color: $primary;
}

#export-entries {
  color: $light-text;
  background-color: $secondary;
}


#manage-entries {
  color: $light-text;
  background-color: $primary;
}

#view-entries, #view-entries_status-refresh {
  color: $light-text;
  background-color: $primary;
}

#start-entries, #start-entries-mng, #start-entries_deployed_service {
  color: $light-text;
  background-color: $green;
}

#stop-entries, #stop-entries-mng, #stop-entries_deployed_service {
  color: $light-text;
  background-color: $red;
}

#refresh-entries {
  color: $light-text;
  background-color: $secondary;
}

#clearButton {
  color: $light-text;
  background-color: $red;
}

.resumeButton {
  color: $light-text;
  background-color: $dark-green;
}

.configBtn {
  color: $light-text;
  background-color: $primary;
}

.invalid {
  color: $invalid;
  margin-bottom: 0;
  margin-top: 1rem;
}

.Critical {
  background-color: $red;
  color: $light-text;
}

.Warning{
  background-color: $yellow;
  color: $dark-text;
}

.Info{
  background-color: $blue;
  color: $dark-text;
}

.Normal{
  background-color: $green;
  color: $dark-text;
}

.Critical {
  background-color: $red;
  font-size: 1.4rem;
  border-radius: 0.3rem;
  width: max-content;

  .severity-button{
    border: none;
    font-size: 1.4rem;
    background-color: inherit;
    color: inherit;

    a {
      color: inherit;
      padding: 0 5px;
    }
  }
}

.Warning, .Info, .Normal {
  font-size: 1.4rem;
  border-radius: 0.3rem;
  width: max-content;

  .severity-button{
    border: none;
    font-size: 1.4rem;
    background-color: inherit; 
    color: inherit;
  
    a {
      color: inherit;
      padding: 0 5px;
    }
  }
}

.Warning:hover, .Critical:hover, .Info:hover, .Normal:hover {
  background-color: $primary;
  font-size: 1.4rem;
  border-radius: 0.3rem;
  width: max-content;

  .severity-button{
    border: none;
    font-size: 1.4rem;
    background-color: inherit; 
  
    a {
      background-color: transparent;
      padding: 0 5px;
      color: $light-text;
    }
  } 
}


.Major {
  background-color: $orange;
}

.Minor {
  background-color: $yellow;
}

.severity {
  border: none;
  border-radius: .3rem;
  // color: $dark-text;
  cursor: default;
  // padding: .2rem .7rem;
  font-size: 1.4rem;
  width: fit-content;
}


.mat-mdc-button, .mat-mdc-unelevated-button, .mat-mdc-raised-button {
  min-width: 6.4rem;
  padding: 0 1.6rem;
}

/* TODO(mdc-migration): The following rule targets internal classes of card that may no longer apply for the MDC version.*/
mat-card-title {
  font-size: 2.4rem;
}

/* TODO(mdc-migration): The following rule targets internal classes of button that may no longer apply for the MDC version.*/
.mat-button-toggle-label-content {
  line-height: 3.6rem !important;
  font-size: 1.4rem;
}

.autoRefresh {
  margin-left: 3px;
  font-size: 1.4rem;
}

label {
  font-size: 1.4rem;
}

.file-upload-button {
  background-color: $primary;
  color: $light-text;
  height: 3.6rem;
}

/* TODO(mdc-migration): The following rule targets internal classes of button that may no longer apply for the MDC version.*/
#save-entries.mat-mdc-unelevated-button.mat-button-disabled.mat-button-disabled {
  background-color: rgba(0, 0, 0, 0.12);
}

.done-icon {
  color: $green;
}


.invalidSD {
  @extend .invalid;
  margin-top: -1rem;
}

.oper-status-ready{
  background-color: #5d5dd3;
}
.oper-status-notready {
 background-color: #FF6A67;
}
