
$light-blue: #798DFE;
$white-blue: #B1BCFF;
$blue-white: #F3F5FF;
$pink: #ff4081;
$dark-pink: #ff0f60;

$violet: #9013FE;
$white: white;
$dark-grey: #4A4A4A;
$light-grey: #B9B9B9;
$grey: #343434;
$sky: #c0caff;



$white-35: rgba(255, 255, 255, 0.35);
$white-80: #FFFFFF80;

$gray-08: rgba(110, 110, 110, 0.8);
$gray-80: #D8D8D880;
$gray-06: rgba(110, 110, 110, 0.6);

$black-08: rgba(0, 0, 0, 0.08);

$pink-15: rgba(255, 92, 147, 0.15);
$blue-15: rgba(83, 109, 254, 0.15);
$green-15: rgba(60, 212, 160, 0.15);
$yellow-15: rgba(255, 194, 96, 0.15);
$violet-15: rgba(144, 19, 254, 0.15);


$shadow-white: #E8EAFC;
$shadow-grey: #B2B2B21A;
$shadow-dark-grey: #9A9A9A1A;

$background-color: #F6F7FF;

$primary: #082246;

$dark-primary-text: rgba(black, 0.87);
$light-primary-text: white;
$secondaryPallet: (
  50: #082246,
  100: #082246,
  200: #082246,
  300: #082246,
  400: #082246,
  500: #678bac,
  600: #082246,
  700: #082246,
  800: #082246,
  900: #1a237e,
  A100: #8c9eff,
  A200: #536dfe,
  A400: #3d5afe,
  A700: #304ffe,
  contrast: (
    50: $dark-primary-text,
    100: $dark-primary-text,
    200: $dark-primary-text,
    300: $light-primary-text,
    400: $light-primary-text,
    500: $light-primary-text,
    600: $light-primary-text,
    700: $light-primary-text,
    800: $light-primary-text,
    900: $light-primary-text,
    A100: $dark-primary-text,
    A200: $light-primary-text,
    A400: $light-primary-text,
    A700: $light-primary-text,
  )
);

$secondary: #678BAC;
$red: #FF6A67;
$orange: #FFAE74;
$yellow: #FFF177;
$green: #BAD878;
$blue: #B3E6F1;
$background: #DFEBF6;
$accent: #EB2C93;
$dark-text: #343434;
$light-text: #fff;
$workspace: #fff;
$active: #c6e4ff;
$select: #76acc7;

$dark-green:#116530;
$logo-color1: #875af2;
$logo-color2: #ff3596;
$invalid: #861a1a;

$lime:#00FF00;

$custom-font: 'Poppins';
